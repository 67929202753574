import React from "react";
import * as yup from "yup";
import cardboardRemoval from "@assets/pricing/cardboard-removal.svg";
import junkRemoval from "@assets/pricing/junk-removal.svg";
import dumpsterRental from "@assets/pricing/dumpster-rental.svg";
import powerWashing from "@assets/pricing/power-washing.svg";
import Upcoming from "@assets/customer_portal/upcoming_group.svg";
import Review from "@assets/customer_portal/review_group.svg";
import Completed from "@assets/customer_portal/completed_group.svg";
import Cancelled from "@assets/raccoon-tree.png";
import Quotes from "@assets/customer_portal/quotes_group.svg";
import { isValidPhoneNumber } from "react-phone-number-input";
import junkRemovalUrl from "@assets/services/waste-removal.jpg";
import cardboardRemovalUrl from "@assets/services/cardboard-removal.jpg";
import dumpsterRentalUrl from "@assets/services/dumpster-rental.jpg";
import pickupUrl from "@assets/pickup.jpg";
import truckUrl from "@assets/truck.jpg";
import fiveYard from "@assets/dumpsterSizes/fiveYard.jpg";
import tenYard from "@assets/dumpsterSizes/tenYard.jpg";
import twentyYard from "@assets/dumpsterSizes/twentyYard.jpg";
import thirtyYard from "@assets/dumpsterSizes/thirtyYard.jpg";
import fourtyYard from "@assets/dumpsterSizes/fourtyYard.jpg";
import { Task, Vehicle } from "../enums/booking";
import { dumpSiteTreatments } from "./dumpSites";
import { adminRecurringFrequency } from "../views/BookingPage/helpers/enums";
import { LOCAL_DEV_ENVS } from "./envs";

export const bookingConfig = {
  businessHours: {
    earliest: {
      hour: 8,
      minute: 0,
    },
    latest: {
      hour: 20,
      minute: 0,
    },
  },
  businessTime: {
    hour: 6,
    minute: 0,
  },
  dumpsterBusinessTiming: {
    startingTime: [9, 12, 15],
    timeGap: 4,
  },
  getTasksDelay: 15,
  payoutThreshold: 0,
};

export const taskTypes = {
  "junk-removal": {
    label: "Junk Removal",
    value: "junk-removal",
    icon: <img src={junkRemoval} alt="J-R" width="20px" height="20px" />,
    default: true,
  },
  "cardboard-removal": {
    label: "Cardboard Removal",
    value: "cardboard-removal",
    icon: <img src={cardboardRemoval} alt="C-R" width="20px" height="20px" />,
    default: true,
  },
  "dumpster-rental": {
    label: "Dumpster Rental",
    value: "dumpster-rental",
    icon: <img src={dumpsterRental} alt="D-R" width="20px" height="20px" />,
    default: true,
  },
  "power-washing": {
    label: "Power Washing",
    value: "power-washing",
    icon: <img src={powerWashing} alt="P-W" width="20px" height="20px" />,
  },
  "alcohol-disposal": {
    label: "Alcohol Disposal",
    value: "alcohol-disposal",
  },
  "beverage-disposal": {
    label: "Beverage Disposal",
    value: "beverage-disposal",
  },
  "compost-disposal": {
    label: "Compost Disposal",
    value: "compost-disposal",
  },
  "hazardous-waste-disposal": {
    label: "Hazardous Waste Disposal",
    value: "hazardous-waste-disposal",
  },
  "sanitizer-disposal": {
    label: "Sanitizer Disposal",
    value: "sanitizer-disposal",
  },
  "solar-panel-disposal": {
    label: "Solar Panel Disposal",
    value: "solar-panel-disposal",
  },
  "solar-panel-sell": {
    label: "Solar Panel Sell",
    value: "solar-panel-sell",
  },
};

export const urlTaskMapping = {
  cardboard_removal: "cardboard-removal",
  junk_removal: "junk-removal",
  dumpster_rental: "dumpster-rental",
  power_washing: "power-washing",
};

export const taskScheduleFrequency = {
  [adminRecurringFrequency.Once]: {
    label: "Once",
    value: "once",
    discount: 0,
    slug: "day",
  },
  [adminRecurringFrequency.Weekly]: {
    label: "Weekly",
    value: "weekly",
    discount: 13,
    slug: "week",
  },
  [adminRecurringFrequency.Monthly]: {
    label: "Monthly",
    value: "monthly",
    discount: 8,
    slug: "month",
  },
};

export const taskFrequencySubscriptionMultiplier = {
  monthly: {
    label: "Monthly",
    value: "monthly",
    multiplier: 1.8, // 1.8x
  },
};

export const bookingSubscriptionTitleMap = {
  [Task.DumpsterRental]: "Unlimited dumpster swaps",
  [Task.CardboardRemoval]: "Unlimited cardboard removal",
  [Task.JunkRemoval]: "Unlimited waste removal",
  [Task.PowerWashing]: "",
};

export const adminTaskScheduleFrequency = {
  ...taskScheduleFrequency,
  [adminRecurringFrequency.Biweekly]: {
    label: "Biweekly",
    value: "bi-weekly",
    slug: "2 week",
  },
  [adminRecurringFrequency.Bimonthly]: {
    label: "Bimonthly",
    value: "bi-monthly",
    slug: "2 month",
  },
  [adminRecurringFrequency.Quarterly]: {
    label: "Quarterly",
    value: "quarterly",
    slug: "quarter",
  },
  [adminRecurringFrequency.SemiAnnually]: {
    label: "Semi-Annually",
    value: "semi-annually",
    slug: "6 month",
  },
};

export const recurringTaskScheduleFrequency = Object.values(adminTaskScheduleFrequency).filter((fr) => fr.value !== taskScheduleFrequency.once.value);

export const bookingStatus = {
  draft: {
    label: "Draft",
    value: "draft",
    color: "#b0c2c0",
    nextActionLabel: "",
    archived: false,
  },
  pending: {
    label: "Pending Confirmation",
    value: "pending",
    color: "#b8b8b8",
    nextActionLabel: "Pending",
    archived: false,
  },
  pending_deposit: {
    label: "Limbo",
    value: "pending_deposit",
    color: "#edcaca",
    nextActionLabel: "Pending",
    archived: false,
  },
  open: {
    label: "Not Started",
    value: "open",
    nextActionLabel: "",
    color: "#c9b49d",
    archived: true,
  },
  booked: {
    label: "Available",
    value: "booked",
    color: "#6ccc73",
    nextActionLabel: "Finding a warrior",
    archived: false,
  },
  current: {
    label: "Booked",
    value: "current",
    color: "#58b2d6",
    nextActionLabel: "Warrior Matched",
    archived: false,
  },
  needs_approval: {
    label: "Needs Approval",
    value: "needs_approval",
    color: "#ff9100",
    nextActionLabel: "In Progress",
    archived: false,
  },
  no_submission: {
    label: "No Submission / Likely Serviced",
    value: "no_submission",
    color: "#edcca1",
    nextActionLabel: "In Progress",
    archived: false,
  },
  rejected: {
    label: "Rejected by admin",
    value: "rejected",
    color: "#dd2c00",
    nextActionLabel: "In Progress",
    archived: false,
  },
  review: {
    label: "In Review",
    value: "review",
    color: "#f5dc6c",
    nextActionLabel: "Waiting for review",
    nextActionText: "Click to review",
    archived: false,
  },
  done: {
    label: "Completed",
    value: "done",
    color: "#009e22",
    nextActionLabel: "Completed",
    archived: false,
  },
  cancelled: {
    label: "Cancelled",
    value: "cancelled",
    color: "#ff5e8c",
    nextActionLabel: "",
    archived: false,
  },
  incomplete: {
    label: "Incomplete",
    value: "incomplete",
    color: "#ca97de",
    nextActionLabel: "",
    archived: true,
  },
  expired: {
    label: "Expired",
    value: "expired",
    color: "red",
    nextActionLabel: "",
    archived: true,
  },
  failed: {
    label: "Failed",
    value: "failed",
    color: "red",
    nextActionLabel: "",
    archived: true,
  },
  implementation: {
    label: "Implementation",
    value: "implementation",
    color: "#007bff",
    nextActionLabel: "Implementation",
    archived: false,
  },
};

export const bookingApprovalStatus = {
  needs_approval: {
    label: "Needs Approval",
    value: "needs_approval",
  },
  review: {
    label: "Approved",
    value: "review",
  },
  review_reduced: {
    label: "Approved With Reduced Payout",
    value: "review_reduced",
  },
  rejected: {
    label: "Rejected by admin",
    value: "rejected",
  },
};

export const bookingApprovalStatusValues = [
  bookingApprovalStatus.needs_approval.value,
  bookingApprovalStatus.review.value,
  bookingApprovalStatus.rejected.value,
  bookingApprovalStatus.review_reduced.value,
];

export const bookingBidStatus = {
  draft: {
    label: "Draft",
    value: "draft",
    textColor: "#000",
    color: "#AAA",
    backgroundColor: "#EEE",
    nextActionLabel: "",
    archived: false,
  },
  available: {
    label: "Available",
    value: "available",
    color: "#FDD835", // yellow
    backgroundColor: "#FFFDE7",
    textColor: "#4c4c4c",
    nextActionLabel: "",
    archived: false,
  },
  ["no-warrior-selected"]: {
    label: "No Warrior Selected",
    value: "no-warrior-selected",
    color: "#FFA726", // orange
    backgroundColor: "#FFF3E0",
    nextActionLabel: "Reopen Bid",
    archived: false,
  },
  completed: {
    label: "Completed",
    value: "completed",
    color: "#66BB6A", // green
    backgroundColor: "#E8F5E9",
    nextActionLabel: "",
    archived: false,
  },
  cancelled: {
    label: "Cancelled",
    value: "cancelled",
    color: "#EF5350", // red
    backgroundColor: "#FFEBEE",
    nextActionLabel: "",
    archived: false,
  },
  failed: {
    label: "Failed",
    value: "failed",
    color: "#EF5350", // red
    backgroundColor: "#FFEBEE",
    nextActionLabel: "",
    archived: false,
  },
};

export const bookingQuoteStatus = {
  draft: {
    label: "Draft",
    value: "draft",
    color: "darkgray",
    backgroundColor: "white",
    nextActionLabel: "",
    archived: false,
  },
  available: {
    label: "Available",
    value: "available",
    color: "#FDD835", // yellow
    backgroundColor: "#FFFDE7",
    textColor: "#4c4c4c",
    nextActionLabel: "",
    archived: false,
  },
  completed: {
    label: "Completed",
    value: "completed",
    color: "#66BB6A", // green
    backgroundColor: "#E8F5E9",
    nextActionLabel: "",
    archived: false,
  },
  cancelled: {
    label: "Cancelled",
    value: "cancelled",
    color: "#EF5350", // red
    backgroundColor: "#FFEBEE",
    nextActionLabel: "",
    archived: false,
  },
  failed: {
    label: "Failed",
    value: "failed",
    color: "#EF5350", // red
    backgroundColor: "#FFEBEE",
    nextActionLabel: "",
    archived: false,
  },
};

export const smsAlerts = {
  priceRange: {
    "<100": {
      label: "Less than $100",
      value: "<100",
    },
    "100-200": {
      label: "$100 - $200",
      value: "100-200",
    },
    ">200": {
      label: "More than $200",
      value: ">200",
    },
  },
  businessPriceRange: {
    "<1000": {
      label: "Less than $1000",
      value: "<1000",
    },
    "1000-5000": {
      label: "$1000 - $5,000",
      value: "1000-5000",
    },
    ">5000": {
      label: "More than $5,000",
      value: ">5000",
    },
  },
  distanceRange: {
    "10-miles": {
      label: "10 miles",
      value: "10",
    },
    "25-miles": {
      label: "25 miles",
      value: "25",
    },
    "50-miles": {
      label: "50 miles",
      value: "50",
    },
    "200-miles": {
      label: "200 miles",
      value: "200",
    },
  },
  alerts: [
    { label: "My tasks this week", value: "weekly", email: false, sms: false },
    { label: "My tasks today", value: "daily", email: false, sms: false },
    { label: "New task in your area", value: "tasks", email: false, sms: false },
  ],
  location: {
    nationwide: { label: "Nationwide", value: "nationwide" },
    address: { label: "Add city or state", value: "address" },
  },
  defaults: {
    priceRange: ["<100", "100-200", ">200"],
    businessPriceRange: ["<1000", "1000-5000", ">5000"],
    location: "nationwide",
    distanceRange: "200",
    taskAvailable: ["cardboard-removal", "junk-removal"],
    vehicleAvailable: false,
    helperAvailable: false,
    businessAlerts: [
      { value: "weekly", email: true, sms: true },
      { value: "daily", email: true, sms: true },
    ],
    alerts: [],
  },
};

export const confirmationPageSurveyOptions = [
  { label: "Google Search", value: "Google Search" },
  { label: "Google Ads", value: "Google Ads" },
  { label: "Yelp Search", value: "Yelp Search" },
  { label: "Facebook / Instagram Ads", value: "Facebook / Instagram Ads" },
  { label: "Bing", value: "Bing" },
  { label: "Linkedin", value: "Linkedin" },
  { label: "Email", value: "Email" },
  { label: "Someone referred me", value: "Someone referred me" },
  { label: "I am a returning customer", value: "I am a returning customer" },
  { label: "Other", value: "Other" },
];

export const vehicleTypes = {
  SUV: {
    label: "SUV",
    value: "SUV",
    disabled: true,
  },
  truck: {
    label: "Truck",
    value: "truck",
  },
  "pick-up-truck": {
    label: "Pick - Up Truck",
    value: "pick-up-truck",
  },
};

export const bidLengths = [
  {
    value: 1,
    label: "1 Day",
  },
  {
    value: 2,
    label: "2 Days",
  },
  {
    value: 3,
    label: "3 Days",
  },
  {
    value: 10,
    label: "10 Days",
  },
];

export const powerwashItems = ["Driver way or garage", "Building siding", "Deck, patio or porch", "Sidewalk", "Roof"];

const isPowerWashing = (task) => task === taskTypes["power-washing"].value;

const isDumpsterRental = (task) => task === taskTypes["dumpster-rental"].value;

export const adminBookingFormSchema = ({ mode, isQuoteFromBid, userType, flags, booking }) => {
  const isBid = mode === "booking-bids";
  const isBooking = mode === "booking";

  let totalAdd = null;
  let lessThanText = "Must be less than Customer Price";
  if (booking?.bundleId) {
    totalAdd = booking.clean_total / 100;
    lessThanText = lessThanText + " + $" + totalAdd + " (Bundle Base)";
  }

  const currentEnv = process.env.REACT_APP_ENV;

  // Different schemas for bids vs quotes vs bookings
  return yup.object().shape({
    task: yup.string().required(),
    description: yup.string().optional(),
    status: yup.string().required(),
    implementation_stage: yup
      .string()
      .optional()
      .when("status", { is: bookingStatus.implementation.value, then: yup.string().required("Implementation Stage is required") }),
    implementation_notes: yup.string().optional(),
    frequency: yup.string().required(),
    frequencyPattern: yup
      .array()
      .of(
        yup.object().shape({
          days: yup.array().of(yup.number()).optional(),
          week: yup.number().optional(),
          month: yup.number().optional(),
        })
      )
      .required(),
    frequencyRepeat: yup.number().optional(),
    frequencyRepeatMode: yup.string().optional(),

    date: yup
      .date()
      .optional()
      .nullable()
      .when("task", { is: () => isBooking, then: yup.date().required() }),
    dateFlexible: yup.boolean().optional().nullable(),
    time: yup
      .object()
      .optional()
      .nullable()
      .when("task", { is: () => isBooking, then: yup.object().required() }),
    taskExpirationDate: yup.date().optional().nullable(),

    // dumpster-rental specific
    pickup: yup.string().when("task", { is: isDumpsterRental, then: yup.string().required("pickup type is required") }),
    "pickup-date": yup.object().when("task", { is: isDumpsterRental, then: yup.object().required("pickup date is required") }),
    "pickup-time": yup.object().when("task", { is: isDumpsterRental, then: yup.object().required("pickup time is required") }),
    vehicle: yup.string().when("task", {
      is: (task) => !isDumpsterRental(task) && isBooking,
      then: yup.string().required("pricing option is required"),
    }),
    ...(isBid
      ? {
          address: yup.object().shape({
            location: yup.string().optional(),
            coordinates: yup.object().optional(),
            placeId: yup.string().optional(),
            zipCode: yup.string().optional(),
            city: yup.string().optional(),
          }),
        }
      : {
          address: yup.object().shape({
            location: yup.string().required("Invalid Location !"),
            coordinates: yup.object().required("Cannot find coordinates"),
            placeId: yup.string().optional(),
            zipCode: yup.string().optional(),
            city: yup.string().optional(),
          }),
        }),
    address_aditional: yup.string().optional(),
    onsite: yup.object().shape({
      instructions: yup.string().optional(),
      images: yup.array().optional().nullable(),
      hours: yup.string().optional(),
      name: yup.string().optional(),
      phone_number: yup
        .string()
        .optional()
        .nullable()
        .test("phone-validation", "Invalid format!", (value) => (value && isValidPhoneNumber(value)) || !value),
      email: yup.string().optional(),
    }),

    phone_number: yup
      .string()
      .required("Phone Numer is required")
      .test("phone-validation", "Invalid format!", (value) => value && isValidPhoneNumber(value)),

    total: yup
      .number()
      .nullable()
      .when("task", {
        is: () => isBooking || isQuoteFromBid,
        then: yup
          .number("Invalid number")
          .typeError("Amount can't be empty!")
          .positive("Amount is invalid!")
          .required()
          .when("task", { is: () => isBid, then: yup.number().optional() })
          .when("task", { is: () => flags?.canBypassTotal || booking?.bundleId, then: yup.number().moreThan(-1).required() }),
      }),

    // for transportation routes
    load_type: yup.string().nullable().optional(),

    /* for quotes only */
    subscription_total: yup.number().nullable().optional(),

    // Payout and payout_dynamic end up being the same via this form
    payout_dynamic: yup
      .number()
      .nullable()
      .when("task", {
        is: () => (isBooking || isQuoteFromBid) && userType !== "superadmin" && !flags?.canBypassTotal,
        then: yup
          .number("Invalid number")
          .required()
          .typeError("Amount can't be empty!")
          .positive("Amount is invalid !")
          .test("less-than-total", lessThanText, function (value) {
            const totalRef = this.resolve(yup.ref("total"));
            return value < totalAdd + totalRef;
          })
          .test("is-decimal", "Invalid decimal", (value) => /^\d*[.{1}\d*]\d*$/.test(Number(value))),
      })
      .when("task", {
        is: () => (isBooking || isQuoteFromBid) && (userType === "superadmin" || flags?.canBypassTotal),
        then: yup
          .number("Invalid number")
          .required()
          .typeError("Amount can't be empty!")
          .positive("Amount is invalid !")
          .test("is-decimal", "Invalid decimal", (value) => /^\d*[.{1}\d*]\d*$/.test(Number(value)))
          .when("task", { is: () => flags?.canBypassTotal, then: yup.number().moreThan(-1).required() }),
      }),

    // Payout and payout_dynamic end up being the same via this form
    warriorBidPrice: yup.number().nullable(),

    // Rebates are cash back from dumpsites
    rebate: yup.number().nullable().optional(),
    rebateCustomer: yup.number().nullable().optional(),

    // Payout will be for dumpsites
    payout_dumpsite: yup.number().nullable().optional(),

    products: yup.array().when("task", { is: !isDumpsterRental && !isPowerWashing, then: yup.array().required() }),
    services: yup.array().when("task", { is: isDumpsterRental, then: yup.array().required() }),
    preferredTreatment: yup.string().optional(),

    stairs: yup.number().when("task", { is: !isDumpsterRental, then: yup.number("Invalid number").positive("Count invalid").required().min(0) }),
    dismantling: yup.number().when("task", { is: !isDumpsterRental, then: yup.number("Invalid number").positive("Count invalid").required().min(0) }),

    coupon: yup.string().optional(),
    payment_method_id: yup
      .string()
      .optional()
      .nullable()
      .when("task", { is: () => isBooking, then: yup.string().required() }),
    paid: yup
      .boolean()
      .nullable()
      .optional()
      .when("task", { is: () => isBooking, then: yup.boolean().required() }),

    businessClient: yup.bool().required(),
    businessClientDetails: yup
      .object()
      .when("businessClient", {
        is: (businessClient) => businessClient,
        then: yup.object().shape({
          name: yup.string().optional(),
          id: yup.string().optional(),
        }),
      })
      .nullable(),

    // Closed By gets pushed into businessClientDetails on the submit
    closedBy: yup.string().when("businessClientDetails", {
      is: (businessClientDetails) => !!businessClientDetails?.name,
      then: yup.string().required("Closed by is required"),
    }),

    // Closed By B2c
    sales_b2c: yup.string().optional().nullable(),
    // Additional Closed by field for SDR outbounds
    sales_sdr: yup.string().optional().nullable(),
    // Additional Closed by field for Customer Implementation Manager
    sales_cim: yup.string().optional().nullable(),
    // outbound vs inbound
    sales_type: yup.string().optional().nullable(),

    first_name: yup.string().required("Must enter first name"),
    last_name: yup.string().optional(),
    email: yup.string().email().required(),
    customer_id: yup.string().optional().nullable(),

    // warrior_id is transporter warrior
    warrior_id: yup.string().when(["task", "warrior_loader_id", "warrior_dumpsite_id"], {
      is: (task, warrior_loader_id, warrior_dumpsite_id) => isPowerWashing(task) || warrior_loader_id || warrior_dumpsite_id,
      then: yup.string().required("Warrior is required"),
      otherwise: yup.string().optional().nullable(),
    }),

    // warrior_loader_id
    warrior_loader_id: yup.string().optional().nullable(),

    // warrior_dumpsite_id
    warrior_dumpsite_id: yup.string().optional().nullable(),

    // payout_loader_warrior is for loader warrior
    payout_loader_warrior: yup.number().when("warrior_loader_id", {
      is: (warrior_loader_id) => {
        if (isBid) return false;
        return isBooking && Boolean(warrior_loader_id);
      },
      then: yup
        .number("Invalid number")
        .required()
        .typeError("Amount can't be empty!")
        .test("is-positive", "Must be >= 0", (val) => val >= 0)
        .test("less-than-total", lessThanText, function (value) {
          if (userType === "superadmin" || flags?.canBypassTotal) return true;
          const totalRef = this.resolve(yup.ref("total"));
          return value < totalAdd + totalRef;
        })
        .test("is-decimal", "Invalid decimal", (value) => /^\d*[.{1}\d*]\d*$/.test(Number(value))),
      otherwise: yup.number().nullable(),
    }),

    // payout_dumpsite_warrior is for disposal warrior
    payout_dumpsite_warrior: yup.number().when(["warrior_id", "warrior_dumpsite_id"], {
      is: (warrior_id, warrior_dumpsite_id) => {
        if (isBid) return false;
        if (isBooking && Boolean(warrior_dumpsite_id)) return true;
        return warrior_id === warrior_dumpsite_id;
      },
      then: yup
        .number("Invalid number")
        .test("can-be-zero-or-positive", "Amount is invalid!", function (value) {
          const { warrior_id, warrior_dumpsite_id } = this.parent;
          if (warrior_id === warrior_dumpsite_id) {
            return value >= 0;
          }
          return value > 0;
        })
        .typeError("Amount can't be empty!")
        .test("less-than-total", lessThanText, function (value) {
          if (userType === "superadmin" || flags?.canBypassTotal) return true;
          const totalRef = this.resolve(yup.ref("total"));
          return value < totalAdd + totalRef;
        })
        .test("is-decimal", "Invalid decimal", (value) => value === 0 || /^\d*[.{1}\d*]\d*$/.test(Number(value))),
      otherwise: yup.number().nullable(),
    }),

    // This is an outdated field
    invoice: yup.string().optional().nullable(),
    invoices: yup.array().optional().nullable(),

    gclId: yup.string().nullable().optional(),
    gclId_intercom: yup.string().nullable().optional(),

    taskImages: yup.array().optional(),
    afterImages: yup.array().optional().nullable(),
    beforeImages: yup.array().optional().nullable(),
    dumpingImages: yup.array().optional().nullable(),

    notes: yup.string().optional(),

    waterSource: yup.boolean().when("task", { is: isPowerWashing, then: yup.boolean().required() }),
    parkingAccess: yup.boolean().when("task", { is: isPowerWashing, then: yup.boolean().required() }),
    powerwashingAreas: yup.array().when("task", { is: isPowerWashing, then: yup.array().optional() }),
    blacklistedWarriors: LOCAL_DEV_ENVS.includes(currentEnv)
      ? yup.array().of(yup.string())
      : yup
          .array()
          .of(
            yup.object().shape({
              id: yup.string().required(),
              fullName: yup.string().required(),
            })
          )
          .optional()
          .nullable(),

    // Bid only
    warriorBidId: yup
      .string()
      .optional()
      .when("task", { is: () => isQuoteFromBid && !flags?.canBypassWarriorBidId, then: yup.string().required() })
      .nullable(),

    bidLengthValue: yup.number().optional().nullable(),

    bidLengthType: yup.string().optional().nullable(),

    // New field to lock price
    isVehicleFeeWaived: yup.boolean().optional().nullable(),
    isServiceFeeWaived: yup.boolean().optional().nullable(),
    isBulkDiscountWaived: yup.boolean().optional().nullable(),
    lockPrice: yup.boolean().optional().nullable(),
    lockDate: yup.boolean().optional().nullable(),

    // Auto Select
    isAutoSelectOn: yup.boolean().optional(),

    // For Bundle auto-select on quotes
    isAutoSelectBundleOn: yup.boolean().optional().nullable(),

    // quote only
    bundleSize: yup.number().max(10).min(1).optional(),
    bundleDiscount: yup.number().max(100).min(0).optional(),
    bundleVariant: yup.string().optional(),

    // Campaigns
    campaign: yup.string().optional().nullable(),

    // Overrides sales new vs recurring
    sales_dashboard_override: yup.string().optional().nullable(),
    sales_commission_override: yup.string().optional().nullable(),

    // impacts & emissions
    impacts: yup
      .object({
        carbon_neutral_optin: yup.boolean().optional(),
        emissions_offset_lbs: yup.number().optional(),
        emissions_offset_fee_estimate: yup.number().optional(),
      })
      .optional()
      .nullable(),
    service_agreement: yup.string().optional(),
    pandadoc_uploaded: yup.boolean().when("service_agreement", {
      is: (service_agreement) => !!service_agreement,
      then: yup.boolean().default(true),
      otherwise: yup.boolean().default(false),
    }),
  });
};

export const warriorPayoutStatus = {
  ERROR: "error",
  NOT_STARTED: "not-started",
  INITIATE: "initiate",
  PAUSED: "paused",
  DONE: "done",
};

export const payoutStatusLabel = {
  [warriorPayoutStatus.ERROR]: "Error",
  [warriorPayoutStatus.NOT_STARTED]: "Not Started",
  [warriorPayoutStatus.INITIATE]: "Initiated",
  [warriorPayoutStatus.PAUSED]: "Paused",
  [warriorPayoutStatus.DONE]: "Done",
};

export const customerTypes = [
  {
    label: "Customer",
    value: false,
  },
  {
    label: "Business",
    value: true,
  },
];

export const sameDayBookingPrice = 1000; //$10
export const warriorEarlyClaimBonus = 1000;

export const bookingStatusNoData = {
  active: {
    title: "No upcoming jobs",
    image: Upcoming,
    subtext: "Book a new job to see your upcoming jobs here.",
    actionName: "Book now",
    actionUrl: "booking?step=initial",
  },
  "needs-action": {
    title: "No jobs require action",
    image: Upcoming,
    subtext: "Book a new job to see your upcoming jobs here.",
    actionName: "Book now",
    actionUrl: "booking?step=initial",
  },
  review: {
    title: "No jobs need review",
    image: Review,
    subtext: "Jobs will appear here for you to review shortly after your trash gets picked up.",
    actionName: "",
    actionUrl: "",
  },
  done: {
    title: "No completed jobs",
    image: Completed,
    subtext: "Jobs that you have reviewed will appear here.",
    actionName: "",
    actionUrl: "",
  },
  cancelled: {
    title: "No cancelled jobs",
    image: Cancelled,
    subtext: "Jobs that you cancel will appear here.",
    actionName: "",
    actionUrl: "",
  },
  quotes: {
    title: "No quotes",
    image: Quotes,
    subtext: "Have a custom order? Any quotes you request from Dimension will appear here.",
    actionName: "",
    actionUrl: "",
  },
};

export const TaskSvgUrl = {
  [Task.JunkRemoval]: junkRemovalUrl,
  [Task.CardboardRemoval]: cardboardRemovalUrl,
  [Task.DumpsterRental]: dumpsterRentalUrl,
  [Task.AlcoholDisposal]: junkRemovalUrl,
  [Task.BeverageDisposal]: junkRemovalUrl,
  [Task.CompostDisposal]: junkRemovalUrl,
  [Task.HazardousWasteDisposal]: junkRemovalUrl,
  [Task.SanitizerDisposal]: junkRemovalUrl,
  [Task.SolarPanelDisposal]: junkRemovalUrl,
};

export const VehicleSvgUrl = {
  [Vehicle.PickupTruck]: pickupUrl,
  [Vehicle.Truck]: truckUrl,
};

// Only add the tasks that are available in the booking flow, junk, cardboard, dumspter
export const TaskOrder = {
  [Task.JunkRemoval]: 0,
  [Task.CardboardRemoval]: 1,
  [Task.DumpsterRental]: 2,
};

export const DumpsterSizeSvgUrl = {
  "5 Yard": fiveYard,
  "10 Yard": tenYard,
  "20 Yard": twentyYard,
  "30 Yard": thirtyYard,
  "40 Yard": fourtyYard,
};

/* This is to be used in booking flow / admin portal to represent bookings with multi-destinations */
export const diversionSurcharge = 0.2;
export const diversionSurchageDisplay = (diversionSurcharge * 100).toFixed() + "%";
export const divertableTreatments = ["recycled", "compost", "donated", "sold"]; // These match the Attribute values for treatments

export const getDropoffTerm = (wasteTreatment) => {
  const term =
    wasteTreatment && Array.isArray(wasteTreatment)
      ? dumpSiteTreatments[wasteTreatment[0]]?.process ?? dumpSiteTreatments["other"]?.process
      : dumpSiteTreatments["other"]?.process;
  return term;
};

export const usaStates = [
  { label: "Alabama", value: "Alabama, USA" },
  { label: "Alaska", value: "Alaska, USA" },
  { label: "Arizona", value: "Arizona, USA" },
  { label: "Arkansas", value: "Arkansas, USA" },
  { label: "California", value: "California, USA" },
  { label: "Colorado", value: "Colorado, USA" },
  { label: "Connecticut", value: "Connecticut, USA" },
  { label: "Delaware", value: "Delaware, USA" },
  { label: "Florida", value: "Florida, USA" },
  { label: "Georgia", value: "Georgia, USA" },
  { label: "Hawaii", value: "Hawaii, USA" },
  { label: "Idaho", value: "Idaho, USA" },
  { label: "Illinois", value: "Illinois, USA" },
  { label: "Indiana", value: "Indiana, USA" },
  { label: "Iowa", value: "Iowa, USA" },
  { label: "Kansas", value: "Kansas, USA" },
  { label: "Kentucky", value: "Kentucky, USA" },
  { label: "Louisiana", value: "Louisiana, USA" },
  { label: "Maine", value: "Maine, USA" },
  { label: "Maryland", value: "Maryland, USA" },
  { label: "Massachusetts", value: "Massachusetts, USA" },
  { label: "Michigan", value: "Michigan, USA" },
  { label: "Minnesota", value: "Minnesota, USA" },
  { label: "Mississippi", value: "Mississippi, USA" },
  { label: "Missouri", value: "Missouri, USA" },
  { label: "Montana", value: "Montana, USA" },
  { label: "Nebraska", value: "Nebraska, USA" },
  { label: "Nevada", value: "Nevada, USA" },
  { label: "New Hampshire", value: "New Hampshire, USA" },
  { label: "New Jersey", value: "New Jersey, USA" },
  { label: "New Mexico", value: "New Mexico, USA" },
  { label: "New York", value: "New York, USA" },
  { label: "North Carolina", value: "North Carolina, USA" },
  { label: "North Dakota", value: "North Dakota, USA" },
  { label: "Ohio", value: "Ohio, USA" },
  { label: "Oklahoma", value: "Oklahoma, USA" },
  { label: "Oregon", value: "Oregon, USA" },
  { label: "Pennsylvania", value: "Pennsylvania, USA" },
  { label: "Rhode Island", value: "Rhode Island, USA" },
  { label: "South Carolina", value: "South Carolina, USA" },
  { label: "South Dakota", value: "South Dakota, USA" },
  { label: "Tennessee", value: "Tennessee, USA" },
  { label: "Texas", value: "Texas, USA" },
  { label: "Utah", value: "Utah, USA" },
  { label: "Vermont", value: "Vermont, USA" },
  { label: "Virginia", value: "Virginia, USA" },
  { label: "Washington", value: "Washington, USA" },
  { label: "West Virginia", value: "West Virginia, USA" },
  { label: "Wisconsin", value: "Wisconsin, USA" },
  { label: "Wyoming", value: "Wyoming, USA" },
];
