import dayjs from "dayjs";
import { haversineDistance } from "@shared/utils/haversine";
import { bookingQuoteStatus, vehicleTypes, taskTypes, bookingStatus } from "../constants/booking";
import { useFlag } from "../shared/hooks/useLDChecker";
import { useSelector } from "react-redux";
import { genCustomUID } from "../shared/utils/generateUID";
import { calculateCarbonNeutralCost, getPaidCarbonNeutralCost } from "./emissions";
import { legacyArrayToObject } from "./recurring";
import { defaultFrequencyRepeatMode } from "../constants/recurring";
import { fetchBookingInvoices } from "../store/actions/invoices";

export const extractNumber = (number) => (number ? number.replace(/[- )(]/g, "") : "");

export const formatBookingId = (x) => x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "); // for better readability eg: 100 023

export const formatPhoneNumber = (phoneNumber) => "+1" + phoneNumber?.toString().slice(-10) ?? "";

export const steadyAdminBookingDefaults = (booking) => ({
  task: booking.task || taskTypes["junk-removal"].value,
  description: booking.description || "",
  implementation_notes: booking.implementation_notes || "",
  implementation_stage: booking.implementation_stage || "",

  frequency: booking.frequency || "once",
  frequencyRepeat: booking?.frequencyRepeat ?? 1,
  frequencyPattern: legacyArrayToObject(booking?.frequencyPattern),
  frequencyRepeatMode: booking?.frequencyRepeatMode ?? defaultFrequencyRepeatMode,
  date: dayjs(booking.date).tz(booking.timeZone) || dayjs().hour(0).minute(0),
  time: booking.time || { key: "0-0", hour: 0, minute: 0 },
  pickup: booking.pickup || "next-booking",
  "pickup-date": dayjs(booking["pickup-date"]).tz(booking.timeZone) || dayjs().hour(0).minute(0),
  "pickup-time": booking["pickup-time"] || { key: "0-0", hour: 0, minute: 0 },
  taskExpirationDate: booking.taskExpirationDate ? dayjs(booking.taskExpirationDate) : null,

  // Addres
  address: booking.address ?? {},
  address_aditional: booking.address_aditional || "",
  phone_number: formatPhoneNumber(booking.phone_number) ?? "",
  onsite: booking.onsite ?? {
    instructions: "",
    images: [],
    hours: "",
    name: "",
    phone_number: "",
    email: "",
  },
  // Booking Elements
  vehicle: booking.vehicle || vehicleTypes["pick-up-truck"].value,
  products: booking.products ?? [],
  services: booking.services ?? [],
  stairs: booking.stairs ?? 0,
  dismantling: booking.dismantling ?? 0,
  coupon: booking.coupon?.couponCode || "",
  waterSource: booking.waterSource || false,
  parkingAccess: booking.parkingAccess || false,
  powerwashingAreas: booking.powerwashingAreas || [],

  // Customer
  first_name: booking.first_name || "",
  last_name: booking.last_name || "",
  email: booking.email || "",
  customer_id: booking.customer_id || "",
  guest_customer: !booking.customer_id || false,
  businessClient: booking.businessClient || !!booking.companyName || false,
  companyName: booking.companyName || "",
  closedBy: booking.businessClientDetails?.closedBy || "",
  businessClientDetails:
    {
      ...booking.businessClientDetails,
      ...(!booking.businessClientDetails?.name && !!booking.companyName
        ? {
            name: booking.companyName,
          }
        : {}),
    } || {},

  sales_b2c: booking.sales_b2c ?? null,
  sales_sdr: booking.sales_sdr ?? null,
  sales_cim: booking.sales_cim ?? null,
  sales_type: booking.sales_type ?? "inbound",
  accountManager: booking.accountManager ?? "",

  // Stripe
  stripe_customer_id: booking.stripeCustomerId || "",

  // Warrior
  warrior_id: booking.warrior_id || "",

  // Gclid
  gclId: booking.gclId || "",
  gclId_intercom: booking.gclId_intercom || "",

  // Images
  taskImages: booking.taskImages || [],

  // Details
  status: booking.status || "",
  notes: booking.notes || "",

  // Pricing
  total: booking.total ? booking.total / 100 : 0,
  payout: booking.payout ? booking.payout / 100 : 0,
  payout_dynamic: booking.payout_dynamic ? booking.payout_dynamic / 100 : 0,
  payout_loader_warrior: booking.payout_loader_warrior ? booking.payout_loader_warrior / 100 : 0,
  payout_dumpsite_warrior: booking.payout_dumpsite_warrior ? booking.payout_dumpsite_warrior / 100 : 0, // TODO-v: check duplication with payout_dumpsite
  payout_dumpsite: booking.payout_dumpsite ? booking.payout_dumpsite / 100 : 0, // will have separate functionality
  rebate: booking.rebate ? booking.rebate / 100 : 0,
  rebateCustomer: booking.rebateCustomer ? booking.rebateCustomer / 100 : 0,

  // Campaigns
  campaign: booking.campaign ?? null,

  // Impacts & Emissions
  impacts: {
    ...booking.impacts,
    carbon_neutral_optin: booking.impacts?.carbon_neutral_optin ?? false,
    emissions_offset_lbs: booking.impacts?.emissions_offset_lbs ?? 0,
    emissions_offset_fee_estimate: booking.impacts?.emissions_offset_fee_estimate ? booking.impacts?.emissions_offset_fee_estimate / 100 : 0,
  },
});

export const constructAdminBookingDefaults = (booking) => ({
  ...steadyAdminBookingDefaults(booking),

  // Payment Section
  payment_method: booking.payment_method || "ACH",
  payment_method_id: booking.payment_method_id || "ACH",
  includeInvoice: booking.includeInvoice || true,
  // invoice: booking.invoice || "",
  invoices: booking.invoices || [],
  invoiceMemo: booking.invoiceMemo || "",
  paid: booking.paid || false,

  afterImages: booking.afterImages || [],
  beforeImages: booking.beforeImages || [],
  dumpingImages: booking.dumpingImages || [],

  warriorBidId: booking.warriorBidId || "",
  bookingBidId: booking.bookingBidId || "",
  bookingQuoteId: booking.bookingQuoteId || "",
  // Override new vs existing
  sales_dashboard_override: booking.sales_dashboard_override ?? null,
  sales_commission_override: booking.sales_commission_override ?? null,

  // roled warriors
  warrior_loader_id: booking.warrior_loader_id || "",
  warrior_dumpsite_id: booking.warrior_dumpsite_id || "",

  service_agreement: booking.service_agreement || "",
  pandadoc_uploaded: booking.service_agreement ? true : false,
});

export const constructAdminBookingQuoteDefaults = (booking, isNew) => ({
  ...steadyAdminBookingDefaults(booking),
  warriorBidId: booking.warriorBidId || "",
  bookingBidId: booking.bookingBidId || "",
  bookingId: booking.bookingId || "",

  isVehicleFeeWaived: !isNew ? booking.isVehicleFeeWaived : false,
  isServiceFeeWaived: !isNew ? booking.isServiceFeeWaived : false,
  isBulkDiscountWaived: !isNew ? booking.isBulkDiscountWaived : false,
  isLockedPrice: !isNew ? booking.isLockedPrice : true,
  isLockedDate: !isNew ? booking.isLockedDate : false,
  isLockedWarrior: true,
  isAutoSelectBundleOn: false,

  status: !isNew ? booking.status : bookingQuoteStatus.available.value,

  subscription_price: booking?.subscription_price ? booking.subscription_price / 100 : null,

  bundleSize: booking?.bundleSize ?? 1,
  bundleDiscount: booking?.bundleDiscount ?? 0,
  bundleVariant: booking?.bundleVariant ?? "",
});

export const constructAdminBookingBidDefaults = (booking, isNew) => ({
  ...steadyAdminBookingDefaults(booking),

  date: !booking.date && booking.bidNumber ? null : dayjs(booking.date).tz(booking.timeZone) || dayjs().hour(0).minute(0),
  dateFlexible: booking.dateFlexible ?? false,

  warriorBidId: booking.warriorBidId ?? "",
  warriorBidPrice: booking.warriorBidPrice ?? 0,
  bookingQuoteId: booking.bookingQuoteId ?? "",
  bookingId: booking.bookingId ?? "",

  bidLengthValue: booking.bidLengthValue ?? 1,
  bidLengthType: booking.bidLengthType ?? "day",

  isVehicleFeeWaived: !isNew ? booking.isVehicleFeeWaived : true,
  isServiceFeeWaived: !isNew ? booking.isServiceFeeWaived : true,
  isLockedPrice: !isNew ? booking.isLockedPrice : true,
  isLockedDate: !isNew ? booking.isLockedDate : true,
  isLockedWarrior: true,
  status: booking.status ?? "open",
  isAutoSelectOn: !isNew ? booking.isAutoSelectOn || false : false,
  blacklistedWarriors: booking?.blacklistedWarriors || [],
});

// Add showNeedsCompletionAndExpiredTabs for AB Test //
export const applyFiltersMyTasks = ({ bookings, filters, showNeedsCompletionAndExpiredTabs }) => {
  let filteredBookings = bookings ?? [];
  const { activeStatus, searchQuery, filterTaskType, filterDateValue, filterLocation } = filters;
  const activeOptions = [bookingStatus.open.value, bookingStatus.booked.value, bookingStatus.current.value];
  const doneOptionsWarrior = [bookingStatus.done.value, bookingStatus.review.value];
  const submissionErrorOptions = [bookingStatus.rejected.value, bookingStatus.needs_approval.value];
  const isNoSubmissionOptions = [bookingStatus.no_submission.value];
  const now = dayjs();
  const twentyNineDaysAgo = now.subtract(29, "days");

  switch (activeStatus) {
    case "active":
      filteredBookings = filteredBookings.filter((booking) => {
        const isDumpsterRentalBooking = booking.task === taskTypes["dumpster-rental"].value;
        let bookingDate = isDumpsterRentalBooking ? getValidDate(booking?.["pickup-date"]) : getValidDate(booking?.date);
        if (booking?.taskExpirationDate && dayjs(booking?.taskExpirationDate).isAfter(dayjs(bookingDate))) {
          bookingDate = getValidDate(booking?.taskExpirationDate);
        }

        const isActiveBooking = activeOptions.includes(booking.status);
        if (!isActiveBooking) return false;

        const isBookingInPast = dayjs(bookingDate).isBefore(now);
        if (isBookingInPast && showNeedsCompletionAndExpiredTabs) return false;

        // booking in past but in active status, excempted provider
        return true;
      });
      break;
    case "needs-completion":
      filteredBookings = filteredBookings.filter((booking) => {
        const isNoSubmissionBooking = isNoSubmissionOptions.includes(booking.status);
        if (isNoSubmissionBooking) return false;

        const isActiveBooking = activeOptions.includes(booking.status);
        const isDumpsterRentalBooking = booking.task === taskTypes["dumpster-rental"].value;
        let bookingDate = isDumpsterRentalBooking ? getValidDate(booking?.["pickup-date"]) : getValidDate(booking?.date);
        if (booking?.taskExpirationDate && dayjs(booking?.taskExpirationDate).isAfter(dayjs(bookingDate))) {
          bookingDate = getValidDate(booking?.taskExpirationDate);
        }

        if (!isActiveBooking) return false;
        if (!showNeedsCompletionAndExpiredTabs) return false;

        // booking date should be in the past
        const isBookingInPast = dayjs(bookingDate).isBefore(now);
        if (!isBookingInPast) return false;

        // booking date should be in the past but within 29 days
        return dayjs(bookingDate).isBefore(twentyNineDaysAgo);
      });
      break;
    case "date-expired":
      filteredBookings = filteredBookings.filter((booking) => {
        const isNoSubmissionBooking = isNoSubmissionOptions.includes(booking.status);
        if (isNoSubmissionBooking) return true;

        const isActiveBooking = activeOptions.includes(booking.status);
        const isDumpsterRentalBooking = booking.task === taskTypes["dumpster-rental"].value;
        let bookingDate = isDumpsterRentalBooking ? getValidDate(booking?.["pickup-date"]) : getValidDate(booking?.date);
        if (booking?.taskExpirationDate && dayjs(booking?.taskExpirationDate).isAfter(dayjs(bookingDate))) {
          bookingDate = getValidDate(booking?.taskExpirationDate);
        }

        if (!isActiveBooking) return false;
        if (!showNeedsCompletionAndExpiredTabs) return false;

        // booking date should be in the past
        const isBookingInPast = dayjs(bookingDate).isBefore(now);
        if (!isBookingInPast) return false;

        // booking date should be in the past but after 29 days
        return dayjs(bookingDate).isAfter(twentyNineDaysAgo);
      });
      break;
    case "warrior-done":
      filteredBookings = filteredBookings.filter((booking) => doneOptionsWarrior.includes(booking.status));
      break;
    case "submissionError":
      filteredBookings = filteredBookings.filter((booking) => submissionErrorOptions.includes(booking.status));
      break;
    default:
      break;
  }

  if (searchQuery?.trim()) {
    filteredBookings = filteredBookings.filter(
      (booking) =>
        booking.orderNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.address?.location?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.address_aditional?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.address?.zipCode?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  switch (filterTaskType) {
    case taskTypes["junk-removal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["junk-removal"].value);
      break;
    case taskTypes["cardboard-removal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["cardboard-removal"].value);
      break;
    case taskTypes["dumpster-rental"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["dumpster-rental"].value);
      break;
    case taskTypes["power-washing"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["power-washing"].value);
      break;
    case taskTypes["hazardous-waste-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["hazardous-waste-disposal"].value);
      break;
    case taskTypes["sanitizer-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["sanitizer-disposal"].value);
      break;
    case taskTypes["compost-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["compost-disposal"].value);
      break;
    case taskTypes["solar-panel-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["solar-panel-disposal"].value);
      break;
    case taskTypes["solar-panel-sell"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["solar-panel-sell"].value);
      break;
    case taskTypes["alcohol-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["alcohol-disposal"].value);
      break;
    case taskTypes["beverage-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["beverage-disposal"].value);
      break;
    case undefined:
      break;
    default:
      break;
  }

  if (filterDateValue) {
    filteredBookings = filteredBookings.filter(
      (booking) =>
        dayjs(booking.date).tz(booking.timeZone).isAfter(dayjs(filterDateValue).startOf("day")) &&
        dayjs(booking.date).tz(booking.timeZone).isBefore(dayjs(filterDateValue).endOf("day"))
    );
  }

  if (filterLocation) {
    filteredBookings = filteredBookings.filter((booking) => booking.address?.zipCode === filterLocation);
  }

  return filteredBookings;
};

// Customer order filters
export const applyFiltersMyOrders = ({ bookings, filters }) => {
  let filteredBookings = bookings ?? [];
  const { activeStatus, searchQuery, filterTaskType, filterDateValue, filterLocation } = filters;
  const activeOptions = [bookingStatus.open.value, bookingStatus.booked.value, bookingStatus.current.value, bookingStatus.no_submission.value];
  const reviewOptions = [bookingStatus.review.value];
  const doneOptions = [bookingStatus.rejected, bookingStatus.needs_approval.value, bookingStatus.done.value];
  const cancelledOptions = [bookingStatus.cancelled.value, bookingStatus.incomplete.value];
  const pendingActionOptions = [bookingStatus.pending.value, bookingStatus.pending_deposit.value];

  switch (activeStatus) {
    case "active":
      filteredBookings = filteredBookings.filter((booking) => activeOptions.includes(booking.status));
      break;
    case bookingStatus.review.value:
      filteredBookings = filteredBookings.filter((booking) => reviewOptions.includes(booking.status));
      break;
    case bookingStatus.done.value:
      filteredBookings = filteredBookings.filter((booking) => doneOptions.includes(booking.status));
      break;
    case "needs-action":
      filteredBookings = filteredBookings.filter((booking) => pendingActionOptions.includes(booking.status));
      break;
    case bookingStatus.cancelled.value:
      filteredBookings = filteredBookings.filter((booking) => cancelledOptions.includes(booking.status));
      break;
    default:
      break;
  }

  if (searchQuery?.trim()) {
    filteredBookings = filteredBookings.filter(
      (booking) =>
        booking.orderNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.address?.location?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.address_aditional?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.address?.zipCode?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  switch (filterTaskType) {
    case taskTypes["junk-removal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["junk-removal"].value);
      break;
    case taskTypes["cardboard-removal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["cardboard-removal"].value);
      break;
    case taskTypes["dumpster-rental"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["dumpster-rental"].value);
      break;
    case taskTypes["power-washing"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["power-washing"].value);
      break;
    case taskTypes["hazardous-waste-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["hazardous-waste-disposal"].value);
      break;
    case taskTypes["sanitizer-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["sanitizer-disposal"].value);
      break;
    case taskTypes["compost-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["compost-disposal"].value);
      break;
    case taskTypes["solar-panel-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["solar-panel-disposal"].value);
      break;
    case taskTypes["solar-panel-sell"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["solar-panel-sell"].value);
      break;
    case taskTypes["alcohol-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["alcohol-disposal"].value);
      break;
    case taskTypes["beverage-disposal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["beverage-disposal"].value);
      break;
    case undefined:
      break;
    default:
      break;
  }

  if (filterDateValue) {
    filteredBookings = filteredBookings.filter(
      (booking) =>
        dayjs(booking.date).tz(booking.timeZone).isAfter(dayjs(filterDateValue).startOf("day")) &&
        dayjs(booking.date).tz(booking.timeZone).isBefore(dayjs(filterDateValue).endOf("day"))
    );
  }

  if (filterLocation) {
    filteredBookings = filteredBookings.filter((booking) => booking.address?.zipCode === filterLocation);
  }

  return filteredBookings;
};

export const isBookingOverlap = (booking, listOfBookings = []) => {
  let retVal = false;
  const bookingTimeHour = booking?.time?.hour || 8;
  for (const item of listOfBookings) {
    const itemTimeHour = item?.time?.hour || 8;
    if (item?.status === "current" && dayjs(item?.date).diff(dayjs(booking?.date), "day") === 0 && itemTimeHour === bookingTimeHour) {
      retVal = true;
      break;
    }
  }
  return retVal;
};

export const createSlotBookingTimeMap = (slotBookings = [], currentLocation, currentCoordinates, timeRange) => {
  // default values are set to 100 bookings in 10 mile radius per hour.
  const { maxBooking = 100, slotRadius = 10 } = currentLocation;

  const hourlyBookings = slotBookings.reduce((acc, booking) => {
    const hour = booking.time?.hour || 8;
    const isBookingInSlotRadius = haversineDistance(currentCoordinates, booking.coordinates) <= slotRadius;
    if (isBookingInSlotRadius) {
      if (acc[hour]) {
        acc[hour] = acc[hour] + 1;
      } else {
        acc[hour] = 1;
      }
    }
    return acc;
  }, {});

  return timeRange.map((time) => ({
    ...time,
    isFull: hourlyBookings[time.hour] >= maxBooking,
  }));
};

export const refreshBooking = (booking) => {
  const repeatedBooking = {
    ...booking,
    status: booking?.paid ? "booked" : "open",
    invoices: [],
    taskImages: [],
    paid: false,
    sameDayBooking: false,
    waiting_list: [],
    payment_method: "ACH",
  };
  delete repeatedBooking.id;
  delete repeatedBooking.stripePaymentId;
  delete repeatedBooking.stripeChargeId;
  delete repeatedBooking.completeTaskSubmissionDate;
  delete repeatedBooking.completion_date;
  delete repeatedBooking["pickup-date"];
  delete repeatedBooking["pickup-time"];
  delete repeatedBooking.dump_site;
  delete repeatedBooking.gclId;
  delete repeatedBooking.gclId_intercom;
  delete repeatedBooking.dumpSiteId;
  delete repeatedBooking.dump_address;
  delete repeatedBooking.dump_cost;
  delete repeatedBooking.dumpingImages;
  delete repeatedBooking.beforeImages;
  delete repeatedBooking.afterImages;
  delete repeatedBooking.warriorPublicLink;
  delete repeatedBooking.warriorPayoutHistory;
  delete repeatedBooking.customerPublicLink;
  delete repeatedBooking.last_notified_warrior;
  delete repeatedBooking.warriorPayoutStatus;
  delete repeatedBooking.last_waitlist_sms;
  delete repeatedBooking.orderNumber;
  delete repeatedBooking.matchHistory;
  delete repeatedBooking.warriorETA;
  delete repeatedBooking.warrior_id;
  delete repeatedBooking.warriorName;
  delete repeatedBooking.adminActions;
  delete repeatedBooking.timeTracking;
  delete repeatedBooking.date;
  delete repeatedBooking.pictureReminder;
  delete repeatedBooking.receiptUrl;
  delete repeatedBooking.payout_surged;
  delete repeatedBooking.task_rated;
  delete repeatedBooking.time;
  delete repeatedBooking.autoCreated;
  delete repeatedBooking.coupon;
  delete repeatedBooking.invoice;
  delete repeatedBooking.receiptUrl;
  delete repeatedBooking.recurringId;
  delete repeatedBooking.experimentGroup;
  delete repeatedBooking.experimentId;
  delete repeatedBooking.sales_cim;

  return repeatedBooking;
};

export const handleAdminBidObject = ({ form, onSet, onAlert, isUpdate }) => {
  const data = {
    ...form,
    total: form.total * 100 ?? 0,
    date: dayjs(form.date).isValid() ? dayjs(form.date).tz(form.address.timeZone) : null,
    timesNotified: 0,
    time: {
      key: form.time?.key,
      hour: form.time?.hour,
      minute: form.time?.minute,
    },
    timeZone: form.address.timeZone,
    status: form.status,
    guest_customer: !form.customer_id,
    ...(form.businessClientDetails
      ? {
          businessClientDetails: {
            ...form.businessClientDetails,
            closedBy: form.closedBy,
          },
          businessClient: form.businessClient,
        }
      : {}),
  };
  delete data.closedBy;
  if (form.task === taskTypes["power-washing"].value) {
    data.products = form.powerwashingAreas.map((item) => {
      return {
        item: {
          description: item,
          isHelperNeeded: false,
        },
        quantity: 1,
      };
    });
  }

  onSet(data);
  onAlert(
    `This bid will be ${isUpdate ? "updated and" : "released to the warriors in the location and"} will expire in ${form.bidLengthValue} ${
      form.bidLengthValue.length > 1 ? "days" : "day"
    }.`
  );
};

export const handleCreateQuoteFromBid = ({ form, onSet, onAlert }) => {
  const data = {
    ...form,
    total: form.total * 100 ?? 0,
    payout: form.payout_dynamic * 100,
    payout_dynamic: form.payout_dynamic * 100,
  };

  delete data.closedBy;

  onSet(data);

  if (!form.warriorBidId) {
    onAlert(
      `You have not selected a warrior. This will create a drafted quote and will be unaccessible to the
      customer until you assign a warrior on the quote page. The bid will remain open for warriors
      until the quote is complete.`
    );
    return;
  }
  onAlert(
    `This will create a quote but the bid will remain open until the quote is completed. To notify the customer, please do so in the Edit Quote page.`
  );
};

export const handleAdminCancelBid = ({ onAlert }) => {
  onAlert(`Are you sure you want to cancel this bid?`);
};

export const calculateBundleMultiplier = (bundleSize, bundleDiscount) => {
  const multiplier = (100 - bundleDiscount) / 100;
  return Number((bundleSize * multiplier).toFixed(1));
};

export const calculateAppliedBundleDiscount = (basePrice, bundlePrice, bundleSize) => {
  const originalPrice = basePrice * bundleSize;
  const discountPercentage = 100 - (bundlePrice / originalPrice) * 100;
  return Math.round(discountPercentage / 5) * 5; // round to nearest 5
};

export const formatBundleConfig = (bundleConfig) => {
  const { count: size, discount, variant = "" } = bundleConfig;
  const multiplier = calculateBundleMultiplier(size, discount);
  return {
    size,
    discount,
    variant,
    multiplier,
  };
};

export const getBookingBundleDiscounts = (booking = {}) => {
  if (booking?.bundleSize && booking?.bundleDiscount && !booking.isSubscriptionOnly) {
    return {
      count: booking.bundleSize,
      discount: booking.bundleDiscount,
      variant: booking.bundleVariant,
    };
  }
  return null;
};

export const getConnectedBookingText = (booking) => {
  if (booking?.associations?.length) {
    const orderIds = booking.associations.map((association) => association.orderId);
    return `Connected with ${orderIds.join(", ")}`;
  }

  return "No connected bookings";
};

export const useBundleConfiguration = (isQuote, booking = {}, onUpdate = (value) => {}) => {
  // if quotes have adjusted bundle info
  const bookingBundleDiscounts = getBookingBundleDiscounts(booking);
  if (isQuote && bookingBundleDiscounts) {
    return formatBundleConfig(bookingBundleDiscounts);
  }

  const profile = useSelector((state) => state.firebase.profile);
  const defaultBundleConfigValue = { discount: 20, count: 3, variant: "" };

  const bundleConfig = useFlag({
    flag: isQuote ? "bundle-quote-discount" : "bundle-discounts",
    profile: profile,
    run: true,
    defaultValue: defaultBundleConfigValue,
    dependencies: [],
    cb: (value) => onUpdate(formatBundleConfig(value)),
  });

  return formatBundleConfig(bundleConfig);
};

export const formatDoItAgainBooking = (booking) => ({
  address: booking.address,
  date: booking.date,
  frequency: booking.frequency,
  invoices: booking.invoices,
  services: booking.services ?? [],
  products: booking.products ?? [],
  payment_method: booking.payment_method,
  stripe_charge_id: booking.stripe_charge_id,
  task: booking.task,
  timeZone: booking.timeZone,
  businessClient: booking.businessClient,
  description: booking.description,
  vehicle: booking.vehicle,
});

const getValidDate = (dateField) => (dateField && dayjs(dateField).isValid() ? dayjs(dateField) : null);
/* This allows us to call it directly without the hooks, particularly when mapping thru an array */
export const calculateBookingDateRangesPastAppointmentDate = (booking = null, submission = null, shouldRun = true) => {
  const isDumpsterTask = booking?.task === taskTypes["dumpster-rental"].value;
  let bookingDate = isDumpsterTask ? getValidDate(booking?.["pickup-date"]) : getValidDate(booking?.date);
  if (booking?.taskExpirationDate && dayjs(booking?.taskExpirationDate).isAfter(dayjs(bookingDate))) {
    bookingDate = getValidDate(booking?.taskExpirationDate);
  }
  const completionDate = submission
    ? getValidDate(submission?.completion_date) || getValidDate(submission?.createdAt)
    : getValidDate(booking?.completeTaskSubmissionDate) || getValidDate(booking?.completion_date);
  const activeOptions = [
    bookingStatus.booked.value,
    bookingStatus.current.value,
    bookingStatus.needs_approval.value,
    bookingStatus.no_submission.value,
  ];

  // if warrior completes task than take completion date as end date
  const endDate = completionDate ?? dayjs();
  const isWithinRangeOfDaysPastAppointmentDate = (start = 0, end = 0) => {
    if (bookingDate) {
      return bookingDate.isBefore(endDate.subtract(start, "days")) && bookingDate.isAfter(endDate.subtract(end, "days"));
    }
    return false;
  };

  let isWithinEarlyDays = false;
  let isWithinMiddleDays = false;
  let isWithinLateDays = false;
  let isBeyondLateDays = false;

  if (shouldRun && activeOptions.includes(booking?.status)) {
    isWithinEarlyDays = isWithinRangeOfDaysPastAppointmentDate(0, 7); // within 7 days
    isWithinMiddleDays = isWithinRangeOfDaysPastAppointmentDate(7, 14); // within 7 to 14 days
    isWithinLateDays = isWithinRangeOfDaysPastAppointmentDate(15, 29); // within 15 to 29 days
    isBeyondLateDays = bookingDate ? dayjs(bookingDate).isBefore(endDate.subtract(29, "days")) : false; // if beyond 29 days
  }

  return {
    isWithinEarlyDays,
    isWithinMiddleDays,
    isWithinLateDays,
    isBeyondLateDays,
  };
};

export const useCalculateBookingDateRangesUtils = () => {
  const profile = useSelector((state) => state.firebase.profile);

  const flagCalculateDateRanges = useFlag({
    flag: "complete-task-reduction",
    profile: profile,
    run: true,
    defaultValue: false,
    dependencies: [],
  });

  return {
    flagCalculateDateRanges,
    calculateBookingDateRangesPastAppointmentDate: (booking, submission = null) =>
      calculateBookingDateRangesPastAppointmentDate(booking, submission, flagCalculateDateRanges),
  };
};

export const changeTrackingFields = {
  total: {
    field: "total",
    type: "number",
    attribute_group: "change-total-reasons",
  },
  payout: {
    field: "payout",
    type: "number",
    attribute_group: "change-payout-reasons",
  },
  payout_loader_warrior: {
    field: "payout_loader_warrior",
    type: "number",
    attribute_group: "change-payout-reasons",
  },
  payout_dumpsite_warrior: {
    field: "payout_dumpsite_warrior",
    type: "number",
    attribute_group: "change-payout-reasons",
  },
  date: {
    field: "date",
    type: "date",
    attribute_group: "change-date-reasons",
  },
  warrior_id: {
    field: "warrior_id",
    type: "string",
    attribute_group: "change-warrior-reasons",
  },
  status: {
    field: "status",
    type: "string",
    attribute_group: "change-status-reasons",
  },
};

// tracker fields: "total", "payout_dynamic", "date", "warrior_id", "status"
export const calculateChangeLog = (data, booking) => {
  // build a change structure like this: [{ field: "total", type: "number", before: 100, after: 200 }, ...],
  const changes = Object.values(changeTrackingFields).reduce((acc, item) => {
    const before = booking[item.field];
    const after = data[item.field];
    const change = {
      id: genCustomUID(5),
      field: item.field,
      type: item.type,
      before,
      after,
      reasons: [],
      notes: "",
    };

    if (after === undefined || after === null) return acc;

    switch (item.type) {
      case "date":
        if (dayjs(before).unix() === dayjs(after).unix()) return acc;
        break;
      case "number":
        // if before is NaN and after is 0, then do not record it as a change
        if (isNaN(before) && after === 0) return acc;
        if (isNaN(before) && isNaN(after)) return acc;
        if (Math.round(before) === Math.round(after)) return acc;
        if (isNaN(before)) change.before = 0;
        break;
      case "string":
        if (before === after) return acc;
        break;
      default:
        break;
    }

    // only record change in status if it from something to "cancelled" only
    if (item.field === "status" && after !== "cancelled") return acc;

    // if warrior_id changes from "", null or undefined to a value, then do not record it as a change
    // lets not track assignment of warrior, only unassignment and reassignment
    if (item.field === "warrior_id" && !before) return acc;

    acc.push(change);
    return acc;
  }, []);

  return changes;
};

// Display total for booking => original total + additional charges (multiple)
export const bookingDisplayTotal = (booking) => {
  const originalBookingTotal = Math.floor(booking.total);

  // calculate carbon neutral cost charged or paid
  const carbonNeutralCost = getPaidCarbonNeutralCost({ impacts: booking.impacts }) || calculateCarbonNeutralCost({ impacts: booking.impacts });

  const additional_charges = carbonNeutralCost; // + other additional charges
  const bookingTotal = originalBookingTotal + additional_charges;

  return bookingTotal;
};

export const openOutstandingInvoice = async (bookingId, setter) => {
  setter?.(true);
  const invoices = await fetchBookingInvoices(bookingId);
  if (!invoices) return;

  const outstandingInvoices = invoices.filter((invoice) => invoice.status === "open");
  const link = outstandingInvoices?.[0]?.hosted_invoice_url || "";

  setter?.(false);
  if (link) {
    window.open(link, "_blank");
  }
};

export const getBookingWarriorPayoutByRole = (booking, user) => {
  const isTransporterWarrior = booking?.warrior_id === user?.uid;
  const isLoaderWarrior = booking?.warrior_loader_id === user?.uid;
  const isDumpsiteWarrior = booking?.warrior_dumpsite_id === user?.uid;

  if (isTransporterWarrior) {
    return booking?.payout_dynamic || booking?.payout || 0;
  }

  if (isLoaderWarrior) {
    return booking?.payout_loader_warrior || 0;
  }

  if (isDumpsiteWarrior) {
    return booking?.payout_dumpsite_warrior || booking?.payout_dumpsite || 0;
  }

  return booking?.payout_dynamic || booking?.payout || 0;
};

// used to derive the role of the user in the booking - only used in local env
export const getBookingWarriorRole = (booking, userId) => {
  const isTransporterWarrior = booking?.warrior_id === userId;
  const isLoaderWarrior = booking?.warrior_loader_id === userId;
  const isDumpsiteWarrior = booking?.warrior_dumpsite_id === userId;

  if (isTransporterWarrior) {
    return "Transporter Warrior";
  }

  if (isLoaderWarrior) {
    return "Loader Warrior";
  }

  if (isDumpsiteWarrior) {
    return "Dumpsite Warrior";
  }

  return "Warrior";
};

// frontend function to determine if a booking (firestore document) contains hazardous material
// this should prevent warriors from claiming the task
export const isBookingHazardous = (booking) => {
  if (!booking) return false;
  const isHazCampaign = ["hazardous-waste-disposal", "sanitizer-disposal", "alcohol-disposal"].includes(booking?.campaign);
  const isHazClass = booking?.openaiClass?.includes("hazardous");
  const hasHazProducts = booking?.products?.some((product) => ["sanitizer", "hazardous-waste"].includes(product?.wasteStream));
  return isHazCampaign || isHazClass || hasHazProducts;
};
